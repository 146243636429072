<template>
  <Component
    :is="isAdminMode ? 'SortableItem' : 'div'"
    class="section-block"
    :index="index"
    :class="{
      'my-auto': !sectionIsMultiBlock,

      'lg:col-span-4': width === '1:3',
      'lg:col-span-6': width === '1:2',
      'lg:col-span-8': width === '2:3',
      'lg:col-span-12': width === '1:1',

      'lg:col-start-5':
        (width === '1:3' && pos === 'only' && halign === 'center') ||
        (width === '2:3' && pos === 'only' && halign === 'end'),
      'lg:col-start-4':
        width === '1:2' && pos === 'only' && halign === 'center',
      'lg:col-start-3':
        width === '2:3' && pos === 'only' && halign === 'center',

      'lg:col-start-9': width === '1:3' && pos === 'only' && halign === 'end',
      'lg:col-start-7': width === '1:2' && pos === 'only' && halign === 'end',
    }"
  >
    <Block
      :meta-data="blockMetaData"
      :parent="parent"
      :index="index"
      :item="item"
    />
  </Component>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'BlockWrapper',

  components: {
    Block: () => import('@/components/Block'),
    SortableItem: () => import('@/components/SortableItem'),
  },

  props: {
    index: {
      type: Number,
      default: null,
    },

    item: {
      type: Object,
      default() {
        return {}
      },
    },

    parent: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },

    blockMetaData: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    isAdminMode: get('isAdminMode'),

    isPageHeader() {
      return this.item.name === 'PageHeader'
    },

    halign() {
      return this.parent.props.halign ?? 'center'
    },

    width() {
      return this.item.props.width ?? '1:1'
    },

    pos() {
      return this.blockMetaData.pos
    },

    sectionIsMultiBlock() {
      return this.parent.items.length > 1
    },
  },
}
</script>
