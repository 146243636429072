var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isAdminMode ? 'SortableItem' : 'div',{tag:"Component",staticClass:"section-block",class:{
    'my-auto': !_vm.sectionIsMultiBlock,

    'lg:col-span-4': _vm.width === '1:3',
    'lg:col-span-6': _vm.width === '1:2',
    'lg:col-span-8': _vm.width === '2:3',
    'lg:col-span-12': _vm.width === '1:1',

    'lg:col-start-5':
      (_vm.width === '1:3' && _vm.pos === 'only' && _vm.halign === 'center') ||
      (_vm.width === '2:3' && _vm.pos === 'only' && _vm.halign === 'end'),
    'lg:col-start-4':
      _vm.width === '1:2' && _vm.pos === 'only' && _vm.halign === 'center',
    'lg:col-start-3':
      _vm.width === '2:3' && _vm.pos === 'only' && _vm.halign === 'center',

    'lg:col-start-9': _vm.width === '1:3' && _vm.pos === 'only' && _vm.halign === 'end',
    'lg:col-start-7': _vm.width === '1:2' && _vm.pos === 'only' && _vm.halign === 'end',
  },attrs:{"index":_vm.index}},[_c('Block',{attrs:{"meta-data":_vm.blockMetaData,"parent":_vm.parent,"index":_vm.index,"item":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }